$(document).on('turbo:load', function () {
  $('#accounts-new, #accounts-create')
    .find('#account_subdomain')
    .on('change', function () {
      $.ajax({
        url: '/subdomain.js',
        data: {
          d: $('#accounts-new, #accounts-create').find('#account_subdomain').val(),
        },
        dataType: 'script',
      })
    })
})
